import React from 'react';

export default function TitleText(props) {
  const { text, white } = props;

  return (
    <div className="center_flex">
      <h2 style={{color: white ? 'white' : 'black'}}>{text}</h2>
      <hr style={{width: 100, marginTop: -20}}/>
    </div>
  );
}
